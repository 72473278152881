import url from '@/resources/_url'

const head = {
  title: {
    inner: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่จาก TrueCoffee'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่จาก TrueCoffee คุณภาพเหมือนเดิม เพิ่มเติมคือตอบโจทย์ไลฟ์สไตล์ทันสมัยของคนรุ่นใหม่! สั่งง่าย จ่ายสะดวก รวดเร็ว เสิร์ฟถึงที่'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่จาก TrueCoffee'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่จาก TrueCoffee คุณภาพเหมือนเดิม เพิ่มเติมคือตอบโจทย์ไลฟ์สไตล์ทันสมัยของคนรุ่นใหม่! สั่งง่าย จ่ายสะดวก รวดเร็ว เสิร์ฟถึงที่'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    },
    {
      id: 'twitter:card',
      name: 'twitter:card',
      content: 'summary'
    },
    {
      id: 'twitter:site',
      name: 'twitter:site',
      content: '@truecoffee'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/`
    }
  ]
}

export default head
